export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  messages: {
    en: {
      first_name: "First name",
      last_name: "Last name",
      phone_number: "Phone Number",
      logo: "Logo",
      personal_photo: "Personal photo",
      personal_message: "Personal message",
      call_to_action_button: "Call to action button",
      button_text: "Button text",
      none: "None",
      preset: "Preset",
      custom: "Custom",
      preview: "Preview",
      share: "Share",
      personalize: "Personalize",
      view: "View",
      complete_your_profile: "Complete Your Profile",
      personal_details: "Personal Details",
      customize_content_experience: "Customize Content Experience",
      share_on_facebook: "Share on Facebook",
      share_on_linkedin: "Share on LinkedIn",
      share_on_whatsapp: "Share on WhatsApp",
      share_by_email: "Share by Email",
      get_qr_code: "Get QR Code",
      get_link: "Get Link",
      language: "Language",
      ai_description: "Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.",
      ai_regenerate: "Try something different",
      copy_text_create_post: "Copy text & create post",
      copy_link_to_content_experience: "Copy link to your content experience",
      share_link_everywhere: "Share this link everywhere...",
      email: "Email",
      qr_code: "QR Code",
      link: "Link",
      my_experiences: "My Experiences",
      my_profile: "My Profile",
      about_my_business: "About My Business",
      i_am_a_travel_advisor: "I am a travel advisor/agency or similar third-party seller of travel",
      host_agency: "Host Agency",
      agency_association_consortium: "Agency Association / Consortium",
      booking_email: "Booking Email",
      booking_phone_number: "Booking Phone Number",
      iata: "IATA used for booking",
      clia: "CLIA used for booking",
      company_name: "Company Name",
      business_languages: "Languages I conduct business in",
      update: "Update",
      logout: "Logout",
      partnerName_experiences: ":partnerName Experiences",
      cobrand_and_share: "Co-brand & share",
      your_content_experiences: "Your Content Experiences",
      your_experiences: "Your Experiences",
      new_experiences: "New Experiences",
      all_content_experiences: "Your Content Experiences",
      search: "Search",
      experiences: "Experiences",
      cobranded_website: "Co-branded website",
      about: "About",
      your_partnerName_content_experiences: "Your :partnerName Content Experiences",
      content_experiences_description:
        "Content experiences enable you to quickly deliver inspirational content in a highly personalized way. **Click an experience below to get started.**",
      cobrand_the_partnerName_website: "Co-brand the :partnerName website",
      cobrand_website_url_description:
        "Here's your personal, co-branded :partnerName website. Safely share this link with clients - we've transformed the site just for you.",
      copy_link: "Copy Link",
      cobrand_website_any_url_description:
        "It gets even better! You can co-brand any page on the :partnerName website. Simply enter any :partnerName webpage to get your co-branded link.",
      cobrand: "Co-brand",
      contact: "Contact",
      for_travel_brands: "For travel brands",
      recommend: "Recommend",
      recommend_us_to_suppliers: "Recommend us to your favorite suppliers",
      recommend_us_description:
        "Don't you wish all of your suppliers made co-branding this easy? Let your suppliers know they can create content experiences to power your marketing. Click the button and let us craft an email for you!",
      cancel: "Cancel",
      copy_and_paste_in_email: "Copy (and paste into an email)",
      pagination: ":itemsFrom - :itemsTo of :total items.",
      choose_a_content_experience: "Choose a content experience",
      sign_in: "Sign In",
      get_started: "Get Started",
      sign_in_to_account: "Sign in to your account",
      sign_in_description: "Enter your email and we'll send a link that logs you in automatically.",
      email_address: "Email address",
      no_account: "Don't have an account? Sign up",
      sign_up_title: "Sign up to start sharing co-branded experiences",
      sign_up_description: "In a matter of seconds, you can co-brand & share our content experiences to build your business",
      create_account: "Create account",
      have_account: "Already signed up? Sign in",
      personalize_your_content_experience: "Personalize this content experience",
      invitation_description: "Here's a pre-designed multimedia content experience, ready for you to add a personal touch. Enter your email to get started.",
      enter_email_legal: "By entering your email address, you agree to the Terms of Service and Privacy Policy.",
      magic_link_email_sent_title: "Check your email!",
      magic_link_email_sent_description: "To get started, tap the magic link button in the email we sent to :userEmail",
    },
    it: {
      first_name: "Nome",
      last_name: "Cognome",
      phone: "Telefono",
      logo: "Il tuo logo",
      personal_photo: "Foto personale",
      personal_message: "Messaggio personale",
      cta_display: "Pulsante di invito all'azione",
      cta_label: "Testo del pulsante",
      preview: "Anteprima",
      share: "Condividi",
      complete_your_profile: "Completa il tuo profilo",
    },
    es: {
      first_name: "Nombre",
      last_name: "Apellido",
      phone: "Teléfono",
      logo: "Tu logo",
      personal_photo: "Foto personal",
      personal_message: "Mensaje personal",
      cta_display: "Botón de llamada a la acción",
      cta_label: "Texto del botón",
      preview: "Vista previa",
      share: "Compartir",
      complete_your_profile: "Completa tu perfil",
    },
    pt: {
      first_name: "Nome",
      last_name: "Sobrenome",
      phone: "Telefone",
      logo: "Seu logotipo",
      personal_photo: "Foto pessoal",
      personal_message: "Mensagem pessoal",
      cta_display: "Botão de chamada para ação",
      cta_label: "Texto do botão",
      preview: "Pré-visualização",
      share: "Compartilhar",
      complete_your_profile: "Complete seu perfil",
    },
    fr: {
      first_name: "Prénom",
      last_name: "Nom de famille",
      phone: "Téléphone",
      logo: "Votre logo",
      personal_photo: "Photo personnelle",
      personal_message: "Message personnel",
      cta_display: "Bouton d'appel à l'action",
      cta_label: "Texte du bouton",
      preview: "Aperçu",
      share: "Partager",
      complete_your_profile: "Complétez votre profil",
    },
    de: {
      first_name: "Vorname",
      last_name: "Nachname",
      phone: "Telefon",
      logo: "Ihr Logo",
      personal_photo: "Persönliches Foto",
      personal_message: "Persönliche Nachricht",
      cta_display: "Call-to-Action-Schaltfläche",
      cta_label: "Schaltflächentext",
      preview: "Vorschau",
      share: "Teilen",
      complete_your_profile: "Vervollständigen Sie Ihr Profil",
    },
    zh: {
      first_name: "名字",
      last_name: "姓氏",
      phone_number: "电话号码",
      logo: "标志",
      personal_photo: "个人照片",
      personal_message: "个人信息",
      call_to_action_button: "行动号召按钮",
      button_text: "按钮文本",
      none: "无",
      preset: "预设",
      custom: "自定义",
      preview: "预览",
      share: "分享",
      personalize: "个性化",
      view: "查看",
      complete_your_profile: "完成您的资料",
      personal_details: "个人信息",
      customize_content_experience: "自定义内容体验",
      share_on_facebook: "分享到 Facebook",
      share_on_linkedin: "分享到 LinkedIn",
      share_on_whatsapp: "分享到 WhatsApp",
      share_by_email: "通过电子邮件分享",
      get_qr_code: "获取二维码",
      get_link: "获取链接",
      language: "语言",
      ai_description: "我们的 AI 助手正在为您撰写内容！只需复制、粘贴、编辑并分享。",
      ai_regenerate: "尝试不同的内容",
      copy_text_create_post: "复制文本并创建帖子",
      copy_link_to_content_experience: "复制内容体验链接",
      share_link_everywhere: "将此链接分享至任何地方……",
      email: "电子邮件",
      qr_code: "二维码",
      link: "链接",
      my_experiences: "我的体验",
      my_profile: "我的资料",
      about_my_business: "关于我的业务",
      i_am_a_travel_advisor: "我是旅行代理/机构或类似的第三方旅行销售人员",
      host_agency: "主办机构",
      agency_association_consortium: "机构协会/联盟",
      booking_email: "预订邮箱",
      booking_phone_number: "预订电话",
      iata: "用于预订的 IATA",
      clia: "用于预订的 CLIA",
      company_name: "公司名称",
      business_languages: "我使用的业务语言",
      update: "更新",
      logout: "登出",
      partnerName_experiences: ":partnerName 的体验",
      cobrand_and_share: "联合品牌并分享",
      your_content_experiences: "您的内容体验",
      your_experiences: "您的体验",
      new_experiences: "新的体验",
      all_content_experiences: "您的所有内容体验",
      search: "搜索",
      experiences: "体验",
      cobranded_website: "联合品牌网站",
      about: "关于",
      your_partnerName_content_experiences: "您的 :partnerName 内容体验",
      content_experiences_description: "内容体验使您能够以高度个性化的方式快速传递启发性内容。点击下方体验开始吧。",
      cobrand_the_partnerName_website: "联合品牌 :partnerName 网站",
      cobrand_website_url_description: "这是您个人的联合品牌 :partnerName 网站。安全地与客户分享此链接——我们已为您量身定制了该网站。",
      copy_link: "复制链接",
      cobrand_website_any_url_description:
        "更棒的是！您可以为 :partnerName 网站上的任何页面创建联合品牌链接。只需输入任何 :partnerName 网页即可获取您的联合品牌链接。",
      cobrand: "联合品牌",
      contact: "联系",
      for_travel_brands: "针对旅行品牌",
      recommend: "推荐",
      recommend_us_to_suppliers: "向您喜欢的供应商推荐我们",
      recommend_us_description:
        "您是否希望所有供应商都能让联合品牌变得如此简单？让您的供应商知道他们可以创建内容体验来推动您的营销。点击按钮，我们会为您制作一封电子邮件！",
      cancel: "取消",
      copy_and_paste_in_email: "复制（并粘贴到电子邮件中）",
      pagination: ":itemsFrom - :itemsTo，共 :total 项。",
      choose_a_content_experience: "选择内容体验",
      sign_in: "登录",
      get_started: "开始",
      sign_in_to_account: "登录您的账户",
      sign_in_description: "输入您的电子邮件，我们将发送一个自动登录的链接给您。",
      email_address: "电子邮箱地址",
      no_account: "没有账户？立即注册",
      sign_up_title: "注册以开始分享联合品牌体验",
      sign_up_description: "几秒钟内，您可以联合品牌并分享我们的内容体验来发展您的业务",
      create_account: "创建账户",
      have_account: "已经注册？登录",
      personalize_your_content_experience: "个性化您的内容体验",
      invitation_description: "这里是一个预设计的多媒体内容体验，您可以随意添加个人风格。输入您的电子邮箱即可开始。",
      enter_email_legal: "通过输入您的电子邮箱地址，您同意服务条款和隐私政策。",
      magic_link_email_sent_title: "检查您的电子邮件！",
      magic_link_email_sent_description: "要开始，请点击我们发送到 :userEmail 的电子邮件中的魔法链接按钮",
    },
  },
}));
