<template>
  <NuxtLayout name="default">
    <Band spaced>
      <div>
        <div class="flex flex-col space-y-6 md:flex-row md:items-end md:justify-between md:space-x-6 md:space-y-0 lg:space-x-12">
          <div>
            <Heading type="h1" variant="h2" :flourish="$t('cobrand_and_share')" flourish-color="brand" :ui="{ base: 'mt-1', flourish: 'uppercase' }">
              {{ user?.content_experiences_count ? $t("your_content_experiences") : $t("choose_a_content_experience") }}
            </Heading>
            <p class="mt-4 max-w-xl text-base" v-html="$markdown($t('content_experiences_description'))" />
          </div>
          <SelectInput
            name="selected_partner"
            endpoint="options/partners"
            no-selection-label="Select Brand"
            :modelValue="partnerSlug"
            @update:model-value="onPartnerChange"
            :ui="{ options: 'lg:max-h-96 md:right-0' }"
          />
        </div>

        <div class="mt-12">
          <NavGroup v-if="user?.content_experiences_count" v-slot="{ ready, size, position, duration }">
            <div class="absolute inset-x-0 bottom-0 h-0.5 bg-gray-100" />

            <div
              v-if="ready"
              :style="{
                '--size': size,
                '--position': position,
                '--duration': duration,
              }"
              class="absolute bottom-0 left-0 h-0.5 w-[--size] translate-x-[--position] bg-primary transition-[width,transform] duration-[--duration]"
            />

            <NavList class="relative flex items-stretch gap-8">
              <NavItem v-slot="{ setActive, isActive }" :active="$route.name === 'experiences'">
                <NuxtLink
                  to="/experiences"
                  :class="[isActive ? 'text-brand hover:cursor-default hover:text-brand' : 'text-gray-500 hover:text-gray-700']"
                  class="block pb-4 text-sm font-medium transition"
                  @click.prevent="setActive"
                >
                  {{ $t("your_experiences") }}
                </NuxtLink>
              </NavItem>

              <NavItem v-slot="{ setActive, isActive }" :active="$route.name === 'experiences-new'">
                <NuxtLink
                  to="/experiences/new"
                  :class="[isActive ? 'text-brand hover:cursor-default hover:text-brand' : 'text-gray-500 hover:text-gray-700']"
                  class="block pb-4 text-sm font-medium transition"
                  @click.prevent="setActive"
                >
                  {{ $t("new_experiences") }}
                  <span
                    v-if="user?.new_content_experiences_count"
                    class="ml-3 hidden rounded-full bg-pink-500 px-2.5 py-0.5 text-xs font-semibold text-white md:inline-flex md:items-center"
                    >{{ user?.new_content_experiences_count }}</span
                  >
                </NuxtLink>
              </NavItem>

              <NavItem v-if="false" v-slot="{ setActive, isActive }" :active="$route.name === 'experiences-archived'" class="ml-auto">
                <NuxtLink
                  to="/experiences/archived"
                  :class="[isActive ? 'text-brand hover:cursor-default hover:text-brand' : 'text-gray-500 hover:text-gray-700']"
                  class="block pb-4 text-sm font-medium transition"
                  @click.prevent="setActive"
                >
                  Archived Experiences
                </NuxtLink>
              </NavItem>
            </NavList>
          </NavGroup>

          <NuxtPage class="mt-8" />
        </div>
      </div>

      <LazyRecommendToSuppliersBanner v-if="user?.content_experiences_count" />
    </Band>
  </NuxtLayout>
</template>

<script setup lang="ts">
const { user } = useAuth();

const partnerSlug = ref(null);

function onPartnerChange(partnerSlug?: string | null) {
  if (partnerSlug) {
    return navigateTo(`/${partnerSlug}/experiences`);
  }
}

const items = ["Overview", "Integrations", "Activity", "Domains", "Usage", "Monitoring"];
</script>
