import revive_payload_client_tWyHjydIWn from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KpIecc34Fy from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_abrwjCWJ3D from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_w0EvW4E8T5 from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_MtUehM1tJc from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/buddy/inspires/.nuxt/components.plugin.mjs";
import prefetch_client_NsZuTOOYfi from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8FdH5i2Rkc from "/buddy/inspires/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.19.0_rollup@4.14.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_nxGJbVgEow from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_rollup@4.14.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_4spKibp8gE from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+i18n@9.1.2_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__rollup@4.14.1_typescrip_vgvup6jy77a5qqtcrzsicc3sgm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_Ereapfz4TV from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+i18n@9.1.2_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__rollup@4.14.1_typescrip_vgvup6jy77a5qqtcrzsicc3sgm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_9TKTx8Sufk from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+i18n@9.1.2_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__rollup@4.14.1_typescrip_vgvup6jy77a5qqtcrzsicc3sgm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_xGIIeaF9gU from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/buddy/inspires/plugins/auth.ts";
import cn_WJinXCj4S6 from "/buddy/inspires/plugins/cn.ts";
import fontawesome_cn2c4tOOHz from "/buddy/inspires/plugins/fontawesome.ts";
import markdown_nljPFoZ1ix from "/buddy/inspires/plugins/markdown.ts";
import modal_UEFnUXzgTX from "/buddy/inspires/plugins/modal.ts";
import plugin_auto_pageviews_client_Upre85hHv6 from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_rollup@4.14.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
import ssg_detect_ArBqmlAiRL from "/buddy/inspires/node_modules/.pnpm/@nuxtjs+i18n@9.1.2_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__rollup@4.14.1_typescrip_vgvup6jy77a5qqtcrzsicc3sgm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_tWyHjydIWn,
  unhead_KpIecc34Fy,
  router_abrwjCWJ3D,
  payload_client_w0EvW4E8T5,
  check_outdated_build_client_MtUehM1tJc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NsZuTOOYfi,
  plugin_8FdH5i2Rkc,
  plugin_client_nxGJbVgEow,
  switch_locale_path_ssr_4spKibp8gE,
  route_locale_detect_Ereapfz4TV,
  i18n_9TKTx8Sufk,
  chunk_reload_client_xGIIeaF9gU,
  auth_vT9JWWT9pN,
  cn_WJinXCj4S6,
  fontawesome_cn2c4tOOHz,
  markdown_nljPFoZ1ix,
  modal_UEFnUXzgTX,
  plugin_auto_pageviews_client_Upre85hHv6,
  ssg_detect_ArBqmlAiRL
]