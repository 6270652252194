<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    lang: "en-US",
    class: "h-full",
  },
  bodyAttrs: {
    class: "flex h-full flex-col font-sans text-base text-paragraph antialiased",
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Approach Guides` : "Approach Guides";
  },
});
</script>
