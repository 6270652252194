<template>
  <Dropdown v-if="isLoggedIn && user" position="right">
    <template #label>
      <span class="flex items-center space-x-3">
        <img v-if="user.personal_photo" :src="user.personal_photo.thumb_url" class="h-8 w-8 rounded-full" />
        <div v-else class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-supplement">
          <Icon icon="user-circle" />
        </div>
        <span class="hidden md:block">{{ user.first_name || "User" }}</span>
      </span>
    </template>
    <DropdownSection>
      <DropdownItem :button="{ prependIcon: 'photo-video', prependIconPrefix: 'fas', to: '/experiences' }">{{ $t("my_experiences") }}</DropdownItem>
      <DropdownItem :button="{ prependIcon: 'user', to: `/profile` }">{{ $t("my_profile") }}</DropdownItem>
    </DropdownSection>
    <DropdownSection>
      <DropdownItem :button="{ prependIcon: 'arrow-right-from-bracket', prependIconPrefix: 'fas' }" @click="logout">{{ $t("logout") }}</DropdownItem>
    </DropdownSection>
  </Dropdown>

  <nav v-else class="flex items-center space-x-6">
    <NuxtLink :to="getUrl('login')">{{ $t("sign_in") }}</NuxtLink>
    <Button :to="getUrl('signup')" append-icon="long-arrow-right">{{ $t("get_started") }}</Button>
  </nav>
</template>

<script setup lang="ts">
import type { SignupSource } from "~/composables/useSignup";
import { withQuery } from "ufo";

const { isLoggedIn, logout } = useAuth();
const route = useRoute();
const user = useUser();

const { isValidSource } = useSignup();

const getUrl = (action: "login" | "signup") => {
  const source = route.meta.source as SignupSource;
  let url = `/${action}`;

  if (route.fullPath !== "/") {
    url += `?redirect=${route.fullPath}`;
  }

  if (isValidSource(source)) {
    url = withQuery(url, { source });

    if (source !== "inspires" && typeof route.params.partner_slug === "string") {
      url = withQuery(url, { partner: route.params.partner_slug });
    }
  }

  return url;
};
</script>
