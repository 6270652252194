import { default as aboutcttChWc7zCMeta } from "/buddy/inspires/pages/[partner_slug]/about.vue?macro=true";
import { default as copilotb2aS9oOfpFMeta } from "/buddy/inspires/pages/[partner_slug]/copilot.vue?macro=true";
import { default as experiencesdcaO4jIFgjMeta } from "/buddy/inspires/pages/[partner_slug]/experiences.vue?macro=true";
import { default as indexvngHnc8UnYMeta } from "/buddy/inspires/pages/[partner_slug]/index.vue?macro=true";
import { default as sales7nAg2F0akPMeta } from "/buddy/inspires/pages/[partner_slug]/sales.vue?macro=true";
import { default as _91partner_slug_93eLCVklde5YMeta } from "/buddy/inspires/pages/[partner_slug].vue?macro=true";
import { default as cookiesrY4d9GqApjMeta } from "/buddy/inspires/pages/cookies.vue?macro=true";
import { default as _91partner_slug_93PduVSg36dhMeta } from "/buddy/inspires/pages/copilot/[partner_slug].vue?macro=true";
import { default as _91slug_93R7cjjZ1mRkMeta } from "/buddy/inspires/pages/experiences/[slug].vue?macro=true";
import { default as archivedVRfBIf47rnMeta } from "/buddy/inspires/pages/experiences/archived.vue?macro=true";
import { default as indexsjJMK2CYcxMeta } from "/buddy/inspires/pages/experiences/index.vue?macro=true";
import { default as newOCTuWNlQ8QMeta } from "/buddy/inspires/pages/experiences/new.vue?macro=true";
import { default as indexPb6m7RSVFCMeta } from "/buddy/inspires/pages/index.vue?macro=true";
import { default as internationalprgpqZaB8FMeta } from "/buddy/inspires/pages/international.vue?macro=true";
import { default as _91slug_93jI6robE66aMeta } from "/buddy/inspires/pages/invite/[slug].vue?macro=true";
import { default as _91login_token_93ymwW3J5VAwMeta } from "/buddy/inspires/pages/login/[login_token].vue?macro=true";
import { default as indexY9AtsHILTWMeta } from "/buddy/inspires/pages/login/index.vue?macro=true";
import { default as profileeadovcqiaUMeta } from "/buddy/inspires/pages/profile.vue?macro=true";
import { default as recommendZCJF4zS8ahMeta } from "/buddy/inspires/pages/recommend.vue?macro=true";
import { default as signupWymWW2ImmkMeta } from "/buddy/inspires/pages/signup.vue?macro=true";
import { default as indexCj4OUgiuAbMeta } from "/buddy/inspires/pages/u/[user_slug]-[user_hashid]/index.vue?macro=true";
export default [
  {
    name: _91partner_slug_93eLCVklde5YMeta?.name ?? undefined,
    path: _91partner_slug_93eLCVklde5YMeta?.path ?? "/:partner_slug()",
    meta: _91partner_slug_93eLCVklde5YMeta || {},
    alias: _91partner_slug_93eLCVklde5YMeta?.alias || [],
    redirect: _91partner_slug_93eLCVklde5YMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug].vue").then(m => m.default || m),
    children: [
  {
    name: aboutcttChWc7zCMeta?.name ?? "partner_slug-about",
    path: aboutcttChWc7zCMeta?.path ?? "about",
    meta: aboutcttChWc7zCMeta || {},
    alias: aboutcttChWc7zCMeta?.alias || [],
    redirect: aboutcttChWc7zCMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug]/about.vue").then(m => m.default || m)
  },
  {
    name: copilotb2aS9oOfpFMeta?.name ?? "partner_slug-copilot",
    path: copilotb2aS9oOfpFMeta?.path ?? "copilot",
    meta: copilotb2aS9oOfpFMeta || {},
    alias: copilotb2aS9oOfpFMeta?.alias || [],
    redirect: copilotb2aS9oOfpFMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug]/copilot.vue").then(m => m.default || m)
  },
  {
    name: experiencesdcaO4jIFgjMeta?.name ?? "partner_slug-experiences",
    path: experiencesdcaO4jIFgjMeta?.path ?? "experiences",
    meta: experiencesdcaO4jIFgjMeta || {},
    alias: experiencesdcaO4jIFgjMeta?.alias || [],
    redirect: experiencesdcaO4jIFgjMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug]/experiences.vue").then(m => m.default || m)
  },
  {
    name: indexvngHnc8UnYMeta?.name ?? "partner_slug",
    path: indexvngHnc8UnYMeta?.path ?? "",
    meta: indexvngHnc8UnYMeta || {},
    alias: indexvngHnc8UnYMeta?.alias || [],
    redirect: indexvngHnc8UnYMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: sales7nAg2F0akPMeta?.name ?? "partner_slug-sales",
    path: sales7nAg2F0akPMeta?.path ?? "sales",
    meta: sales7nAg2F0akPMeta || {},
    alias: sales7nAg2F0akPMeta?.alias || [],
    redirect: sales7nAg2F0akPMeta?.redirect,
    component: () => import("/buddy/inspires/pages/[partner_slug]/sales.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cookiesrY4d9GqApjMeta?.name ?? "cookies",
    path: cookiesrY4d9GqApjMeta?.path ?? "/cookies",
    meta: cookiesrY4d9GqApjMeta || {},
    alias: cookiesrY4d9GqApjMeta?.alias || [],
    redirect: cookiesrY4d9GqApjMeta?.redirect,
    component: () => import("/buddy/inspires/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: _91partner_slug_93PduVSg36dhMeta?.name ?? "copilot-partner_slug",
    path: _91partner_slug_93PduVSg36dhMeta?.path ?? "/copilot/:partner_slug()",
    meta: _91partner_slug_93PduVSg36dhMeta || {},
    alias: _91partner_slug_93PduVSg36dhMeta?.alias || [],
    redirect: _91partner_slug_93PduVSg36dhMeta?.redirect,
    component: () => import("/buddy/inspires/pages/copilot/[partner_slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R7cjjZ1mRkMeta?.name ?? "experiences-slug",
    path: _91slug_93R7cjjZ1mRkMeta?.path ?? "/experiences/:slug()",
    meta: _91slug_93R7cjjZ1mRkMeta || {},
    alias: _91slug_93R7cjjZ1mRkMeta?.alias || [],
    redirect: _91slug_93R7cjjZ1mRkMeta?.redirect,
    component: () => import("/buddy/inspires/pages/experiences/[slug].vue").then(m => m.default || m)
  },
  {
    name: archivedVRfBIf47rnMeta?.name ?? "experiences-archived",
    path: archivedVRfBIf47rnMeta?.path ?? "/experiences/archived",
    meta: archivedVRfBIf47rnMeta || {},
    alias: archivedVRfBIf47rnMeta?.alias || [],
    redirect: archivedVRfBIf47rnMeta?.redirect,
    component: () => import("/buddy/inspires/pages/experiences/archived.vue").then(m => m.default || m)
  },
  {
    name: indexsjJMK2CYcxMeta?.name ?? "experiences",
    path: indexsjJMK2CYcxMeta?.path ?? "/experiences",
    meta: indexsjJMK2CYcxMeta || {},
    alias: indexsjJMK2CYcxMeta?.alias || [],
    redirect: indexsjJMK2CYcxMeta?.redirect,
    component: () => import("/buddy/inspires/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: newOCTuWNlQ8QMeta?.name ?? "experiences-new",
    path: newOCTuWNlQ8QMeta?.path ?? "/experiences/new",
    meta: newOCTuWNlQ8QMeta || {},
    alias: newOCTuWNlQ8QMeta?.alias || [],
    redirect: newOCTuWNlQ8QMeta?.redirect,
    component: () => import("/buddy/inspires/pages/experiences/new.vue").then(m => m.default || m)
  },
  {
    name: indexPb6m7RSVFCMeta?.name ?? "index",
    path: indexPb6m7RSVFCMeta?.path ?? "/",
    meta: indexPb6m7RSVFCMeta || {},
    alias: indexPb6m7RSVFCMeta?.alias || [],
    redirect: indexPb6m7RSVFCMeta?.redirect,
    component: () => import("/buddy/inspires/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internationalprgpqZaB8FMeta?.name ?? "international",
    path: internationalprgpqZaB8FMeta?.path ?? "/international",
    meta: internationalprgpqZaB8FMeta || {},
    alias: internationalprgpqZaB8FMeta?.alias || [],
    redirect: internationalprgpqZaB8FMeta?.redirect,
    component: () => import("/buddy/inspires/pages/international.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jI6robE66aMeta?.name ?? "invite-slug",
    path: _91slug_93jI6robE66aMeta?.path ?? "/invite/:slug()",
    meta: _91slug_93jI6robE66aMeta || {},
    alias: _91slug_93jI6robE66aMeta?.alias || [],
    redirect: _91slug_93jI6robE66aMeta?.redirect,
    component: () => import("/buddy/inspires/pages/invite/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91login_token_93ymwW3J5VAwMeta?.name ?? "login-login_token",
    path: _91login_token_93ymwW3J5VAwMeta?.path ?? "/login/:login_token()",
    meta: _91login_token_93ymwW3J5VAwMeta || {},
    alias: _91login_token_93ymwW3J5VAwMeta?.alias || [],
    redirect: _91login_token_93ymwW3J5VAwMeta?.redirect,
    component: () => import("/buddy/inspires/pages/login/[login_token].vue").then(m => m.default || m)
  },
  {
    name: indexY9AtsHILTWMeta?.name ?? "login",
    path: indexY9AtsHILTWMeta?.path ?? "/login",
    meta: indexY9AtsHILTWMeta || {},
    alias: indexY9AtsHILTWMeta?.alias || [],
    redirect: indexY9AtsHILTWMeta?.redirect,
    component: () => import("/buddy/inspires/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: profileeadovcqiaUMeta?.name ?? "profile",
    path: profileeadovcqiaUMeta?.path ?? "/profile",
    meta: profileeadovcqiaUMeta || {},
    alias: profileeadovcqiaUMeta?.alias || [],
    redirect: profileeadovcqiaUMeta?.redirect,
    component: () => import("/buddy/inspires/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: recommendZCJF4zS8ahMeta?.name ?? "recommend",
    path: recommendZCJF4zS8ahMeta?.path ?? "/recommend",
    meta: recommendZCJF4zS8ahMeta || {},
    alias: recommendZCJF4zS8ahMeta?.alias || [],
    redirect: recommendZCJF4zS8ahMeta?.redirect,
    component: () => import("/buddy/inspires/pages/recommend.vue").then(m => m.default || m)
  },
  {
    name: signupWymWW2ImmkMeta?.name ?? "signup",
    path: signupWymWW2ImmkMeta?.path ?? "/signup",
    meta: signupWymWW2ImmkMeta || {},
    alias: signupWymWW2ImmkMeta?.alias || [],
    redirect: signupWymWW2ImmkMeta?.redirect,
    component: () => import("/buddy/inspires/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indexCj4OUgiuAbMeta?.name ?? "u-user_slug-user_hashid",
    path: indexCj4OUgiuAbMeta?.path ?? "/u/:user_slug()-:user_hashid()",
    meta: indexCj4OUgiuAbMeta || {},
    alias: indexCj4OUgiuAbMeta?.alias || [],
    redirect: indexCj4OUgiuAbMeta?.redirect,
    component: () => import("/buddy/inspires/pages/u/[user_slug]-[user_hashid]/index.vue").then(m => m.default || m)
  }
]