import validate from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/pages/runtime/validate.js";
import profile_45suggestions_45global from "/buddy/inspires/middleware/profile-suggestions.global.ts";
import redirects_45global from "/buddy/inspires/middleware/redirects.global.ts";
import share_45global from "/buddy/inspires/middleware/share.global.ts";
import manifest_45route_45rule from "/buddy/inspires/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.1_encoding@0.1.13_es_e3y7qirxyegpk4owbgp5lbqfpm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  profile_45suggestions_45global,
  redirects_45global,
  share_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/buddy/inspires/middleware/admin.ts"),
  auth: () => import("/buddy/inspires/middleware/auth.ts"),
  "auto-accept-invite": () => import("/buddy/inspires/middleware/auto-accept-invite.ts"),
  guest: () => import("/buddy/inspires/middleware/guest.ts")
}