<template>
  <font-awesome-icon :icon="[prefix, getIcon]" class="icon" />
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

const props = withDefaults(
  defineProps<{
    icon: IconName;
    prefix?: IconPrefix;
  }>(),
  {
    prefix: "fas",
  }
);

const iconMap: Record<string, IconName> = {
  content: "photo-video",
};

const getIcon = computed(() => iconMap[props.icon] || props.icon);
</script>
